import { observable, action, computed, makeObservable } from 'mobx';

import Ajax from '../services/Ajax';
import { isLvDomain } from '../services/Env';
import Product from '../models/Product';

export enum ActionStatus {
  ADDED = 'variation_added',
  OUT_OF_STOCK = 'variation_out_of_stock',
}

export default class Cart {
  @observable initialized = false;
  @observable products = [];
  @observable variations = [];
  @observable total = 0;
  @observable shipping = 0;
  @observable shippingMethod = null;
  @observable containsBike = false;
  @observable softGoods = false;
  @observable favourites = [];
  @observable lastActionStatus = '';
  @observable discountSummary = null;
  @observable availableShipping = [];
  @observable shippingCountry = isLvDomain() ? 'lv' : 'ee';
  @observable token = '';
  @observable cartDiscount = 0;
  @observable fullPrice = 0;

  constructor() {
    makeObservable(this);
  }

  async create() {
    const { data } = await Ajax.post('carts', {
      countryCode: this.shippingCountry,
    });
    this.setCartData(data);
  }

  async load() {
    const { data } = await Ajax.get('carts', {
      params: {
        countryCode: this.shippingCountry,
      },
    });
    this.setCartData(data);
  }

  async addItem(code: string) {
    const { data } = await Ajax.post(`carts/add/${code}`, {
      countryCode: this.shippingCountry,
    });
    this.setCartData(data);
  }

  async setShippingMethod(slug: string) {
    // Check if shipping method needs to be updated
    if (this.shippingMethod && this.shippingMethod.slug === slug) {
      return;
    }

    const { data } = await Ajax.post(`carts/shipping/${slug}`, {
      countryCode: this.shippingCountry,
    });
    this.setCartData(data);
  }

  async removeItem(code: string, quantity = 1) {
    const { data } = await Ajax.post(`carts/remove/${code}`, {
      quantity,
      countryCode: this.shippingCountry,
    });

    this.setCartData(data);
  }

  async toggleFavourite(slug: string) {
    const { data } = await Ajax.post(`favourites/toggle/${slug}`);
    this.setCartData(data);
  }

  @action setCartData(data) {
    this.products = data.products;
    this.variations = data.variations;
    this.total = data.total;
    this.shipping = data.shipping;
    this.initialized = true;
    this.shippingMethod = data.shippingMethod;
    this.containsBike = data.bikes;
    this.softGoods = data.softGoods;
    this.lastActionStatus = data.actionStatus;
    this.discountSummary = data.discountSummary;
    this.availableShipping = data.availableShipping;
    this.token = data.token;
    this.cartDiscount = data.totalDiscount;
    this.fullPrice = data.originalTotal;

    if (data.favourites) {
      this.favourites = data.favourites;
    }
  }

  @action setShippingCountry(newCountry: string) {
    this.shippingCountry = newCountry;
  }

  @computed get nrOfItems() {
    return this.variations.length;
  }

  @computed get nrOfAllItems() {
    let currentNrOfItems = 0;
    this.variations.forEach((variation) => {
      currentNrOfItems = currentNrOfItems + variation.quantity;
    });
    return currentNrOfItems;
  }

  @computed get nrOfFavourites() {
    return this.favourites.length;
  }

  @computed get totalSum() {
    if (this.total === 0) {
      return 0;
    }
    return (this.total + this.shipping).toFixed(2);
  }

  @computed get itemsTotal() {
    return this.total.toFixed(2);
  }

  @computed get shippingTotal() {
    if (this.total === 0 || this.shipping === 0) {
      return 0.0;
    }
    return this.shipping.toFixed(2);
  }

  @computed get totalDiscount() {
    return this.cartDiscount.toFixed(2);
  }

  @computed get originalTotal() {
    return this.fullPrice.toFixed(2);
  }

  getProduct(productId: number): Product {
    const product = this.products.find(
      (productData) => productData.id === productId,
    );

    if (product) {
      return new Product(product);
    }

    return;
  }
}
