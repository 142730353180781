import Ajax from '../services/Ajax';
import Banner from '../models/Banner';

export enum BannerComponent {
  ABOUT = 'about',
  CATEGORY_BLOCK = 'category-block',
  ERRORS = 'errors',
  HOME = 'home',
  JOB_OFFERS = 'job-offers',
  PARKA = 'parka',
  RETURNS = 'returns',
  SLICE = 'slice',
}

export default class Banners {
  public async loadByComponent(bannerComponent: BannerComponent) {
    const { data } = await Ajax.get(`banners/component/${bannerComponent}`);
    return data.map((banner) => new Banner(banner));
  }

  public async loadByCollection(collection) {
    const { data } = await Ajax.get(`banners/collection/${collection}`);
    return data.map((banner) => new Banner(banner));
  }
}
