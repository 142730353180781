import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import LocalizedLink from '../../../components/LocalizedLink';
import Block from '../Block';
import Ajax from '../../../services/Ajax';
import Image from '../../Image';
import { Sizes } from '../../../constants/Image';
import Translate from '../../../components/Translate/Translate';
import './styles/index.scss';
import parse from 'html-react-parser';

const GET_CATEGORIES = gql`
  query CategoriesMain {
    categoriesMain {
      id
      title
      slug
    }
  }
`;
type MainCategoryItem = {
  id: string;
  title: string;
  slug: string;
};
export default function HomeBlock() {
  const [content, setContent] = useState({ brands: [] });
  const [category, setCategory] = useState('naised');
  const { data: categoryData, loading: categoryLoading } = useQuery<{
    categoriesMain: MainCategoryItem[];
  }>(GET_CATEGORIES);

  const categories = categoryData?.categoriesMain;

  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const loadContent = async (value = '2') => {
    const { data } = await Ajax.get(`/landing/parka/${value}`);
    setContent(data);
  };

  const categoryChange = (slug, id) => {
    setCategory(slug);
    loadContent(id);
  };

  let options;
  if (!categoryLoading && categories) {
    options = categories.map((option, index) => {
      let activeClass = '';

      category === option.slug ? (activeClass += '--selected') : '';

      return (
        <div
          key={index}
          onClick={() => categoryChange(option.slug, option.id)}
          className={`component-homeBlock-option${activeClass}`}
        >
          <h2>{option.title}</h2>
        </div>
      );
    });
  }

  let links;
  if (content['top-categories']) {
    links = content['top-categories'].map((link, index) => {
      const path =
        link.child.parents?.filter((slug) => slug !== 'main').join('/') ||
        category; // category as a backup until cache has been updated

      return (
        <LocalizedLink
          to={`/tooted/${path}/${link.child.slug}`}
          key={index}
          className="component-homeBlock-item"
        >
          <div className="component-homeBlock-item__content">
            <p>{link.child.title}</p>
            <Image
              width="300"
              height="300"
              image={link.child}
              defaultSize={Sizes.W300}
              alt={link.child.title}
            />
          </div>
        </LocalizedLink>
      );
    });
  }

  let topBrands;
  if (content.brands) {
    topBrands = content.brands.map((brand, index) => {
      return (
        <LocalizedLink
          to={`/tooted/${category}?brand=${brand.child.title}`}
          key={index}
          className="component-homeBlock-item"
          label={brand.child.title}
        >
          <div className="component-homeBlock-item__content">
            {parse(brand.child.logo)}
          </div>
        </LocalizedLink>
      );
    });
  }

  return (
    <div className="component-homeBlock">
      <div className="component-homeBlock-options">{options}</div>
      <div className="component-homeBlock-content">
        <div className="component-homeBlock-box">
          <h3>
            <Translate component="homeBlock" keyword="top-category" />
          </h3>
          <div className="component-homeBlock-list">{links}</div>
        </div>
        <div className="component-homeBlock-box--brands">
          <h3>
            <Translate component="homeBlock" keyword="top-brands" />
          </h3>
          <div className="component-homeBlock-list">
            {topBrands}
            <LocalizedLink
              to={'/brandid'}
              className="component-homeBlock-item"
              onClick={scrollToTop}
            >
              <div className="component-homeBlock-item__content">
                <p>
                  {' '}
                  <Translate component="homeBlock" keyword="all-brands" />
                </p>
              </div>
            </LocalizedLink>
          </div>
        </div>
        <Block type="new" slug={category} />
      </div>
    </div>
  );
}
