import Ajax from '../services/Ajax';
import { viewProduct } from '../services/Analytics';
import Product from '../models/Product';

export type ProductPagination = {
  total: number;
  currentPage: number;
  perPage: number;
  lastPage: number;
};

type ProductsResponse = {
  products: Product[];
  pagination: ProductPagination;
};

export default class Products {
  async loadBySlug(slug: string) {
    const { data: response } = await Ajax.get(`products/${slug}`, {
      params: {
        variations: true,
      },
    });

    const product = new Product(response);

    viewProduct(product.id);

    return product;
  }

  async loadRecommendations(slug: string) {
    const { data: products } = await Ajax.get(
      `products/${slug}/recommendations`,
    );
    return products;
  }

  async loadLatest(slug: string) {
    const { data: products } = await Ajax.get(`products/${slug}/latest`);
    return products;
  }

  async loadSeries(slug: string) {
    const { data: response } = await Ajax.get(`products/${slug}/series`);
    return response;
  }

  async loadVariations(slug: string) {
    const { data: variations } = await Ajax.get(`products/${slug}/variations`);
    return variations;
  }

  async loadQuantities(slug: string) {
    const { data: quantities } = await Ajax.get(`products/${slug}/quantities`);
    return quantities.filter((quantity) => quantity.quantity);
  }

  async loadSizeChart(slug: string) {
    const { data: sizes } = await Ajax.get(`sizes/${slug}`);
    return sizes;
  }

  async loadByCategorySlug(
    slug: string,
    page = 1,
    brands?: string,
    colours?: string,
    sizes?: string,
    orderBy?: string,
    order?: string,
    tags?: string,
  ): Promise<ProductsResponse> {
    const { data: response } = await Ajax.get(`categories/${slug}/products`, {
      params: {
        page,
        brands,
        colours,
        sizes,
        order,
        tags,
        order_by: orderBy,
      },
    });

    const products = response.data.map(
      (productData) => new Product(productData),
    );
    const pagination = {
      total: Number(response.total),
      currentPage: Number(response.current_page),
      perPage: Number(response.per_page),
      lastPage: Number(response.last_page),
    };

    return {
      products,
      pagination,
    };
  }
}
