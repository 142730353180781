import React from 'react';
import LocalizedLink from '../LocalizedLink';
import { gql, useQuery } from '@apollo/client';

const GET_SUBCATEGORIES = gql`
  query Categories($categoriesId: ID) {
    categories(id: $categoriesId) {
      id
      title
      slug
    }
  }
`;

type CategoriesItem = {
  id: string;
  title: string;
  slug: string;
};

type Props = {
  parentId: string;
  title: string;
  slug: string;
  subCategorySlug: string;
  onClick: () => void;
};

const SubmenuHoverable = ({
  parentId,
  title,
  slug,
  subCategorySlug,
  onClick,
}: Props) => {
  const { loading, error, data } = useQuery<{
    categories: CategoriesItem[];
  }>(GET_SUBCATEGORIES, {
    variables: { categoriesId: parentId },
  });

  if (loading) {
    return;
  }

  if (error || !data) {
    return null;
  }

  const subCategories = data?.categories || [];

  return (
    <div className="inline-block">
      <div className="mr-24">
        <LocalizedLink
          to={`/tooted/${slug}/${subCategorySlug}`}
          className="cursor-pointer"
          onClick={onClick}
        >
          <span className="relative capitalize font-axi-bold text-lg leading-6 px-0 py-2.5 hover:text-rgreen">
            {title}
          </span>
        </LocalizedLink>
      </div>
      <div className="flex flex-col my-2">
        {subCategories.map((category) => (
          <LocalizedLink
            key={category.id}
            to={`/tooted/${slug}/${subCategorySlug}/${category.slug}`}
            onClick={onClick}
            className="cursor-pointer my-1"
          >
            <span className="text-base leading-6 pl-0 pr-3 py-1.5 hover:text-rgreen">
              {category.title}
            </span>
          </LocalizedLink>
        ))}
      </div>
    </div>
  );
};

export default SubmenuHoverable;
