import React, { useEffect, useState } from 'react';
import BlogItem from '../BlogItem';
import LocalizedLink from '../../../components/LocalizedLink';
import Translate from '../../Translate/Translate';
import { gql, useQuery } from '@apollo/client';
import Loader from '../../Loader/Loader';

const GET_LATESTPOSTS = gql`
  query LatestPosts($ignoredPostSlug: String, $limit: Int) {
    latestPosts(ignoredPostSlug: $ignoredPostSlug, limit: $limit) {
      categories {
        id
        slug
        title
      }
      content
      cover {
        cdn_url
        urls {
          W800
        }
      }
      createdAt
      id
      preview
      slug
      title
    }
  }
`;

type LatestPostsItem = {
  categories: {
    id: string;
    slug: string;
    title: string;
  }[];
  content: string;
  cover: {
    cdn_url: string;
    urls: {
      W800: string;
    };
  };
  createdAt: string;
  id: string;
  preview: string;
  slug: string;
  title: string;
};

type Props = { ignoredPostSlug?: string; limit?: number };

export default function BlogBlock({ ignoredPostSlug, limit }: Props) {
  const { loading, error, data } = useQuery<{
    latestPosts: LatestPostsItem[];
  }>(GET_LATESTPOSTS, {
    variables: { ignoredPostSlug, limit },
  });
  const [allPosts, setAllPosts] = useState([]);

  useEffect(() => {
    if (!loading && !error) {
      setAllPosts(data.latestPosts);
    }
  }, [loading, error, data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (loading) {
    return (
      <div className="flex justify-center m-10">
        <Loader />
      </div>
    );
  }

  if (error) {
    return null;
  }

  const posts = data.latestPosts;

  return (
    <div>
      <div className="flex flex-wrap items-end mb-2.5">
        <h3 className="flex grow shrink-0 basis-auto">
          <Translate component="blog" keyword="new-posts" />
        </h3>
        <LocalizedLink
          className="border border-solid border-rgrey-light rounded transition-all duration-100 font-axi-bold mt-2.5 py-3 px-4 hover:bg-black hover:text-white"
          to={'/blogi'}
          onClick={scrollToTop}
        >
          <Translate component="blog" keyword="all-posts" />
        </LocalizedLink>
      </div>
      <div className="flex flex-wrap -mx-2.5 my-0 md:flex-nowrap">
        {allPosts
          ? posts.map((post, index) => <BlogItem key={index} data={post} />)
          : null}
      </div>
    </div>
  );
}
