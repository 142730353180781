import { action, makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AuthenticationStore from '../../stores/Authentication';
import Translate from '../Translate/Translate';
import Alert, { Type } from './../Alert';
import './styles/index.scss';

interface Props {
  authentication?: AuthenticationStore;
  toggle?: () => void;
}

@inject('authentication')
@observer
export default class ResetPassword extends React.Component<Props> {
  @observable resetOpen = false;
  @observable errorMessage = '';
  @observable feedback = '';

  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.sendResetEmail = this.sendResetEmail.bind(this);
    this.setResetValue = this.setResetValue.bind(this);
  }

  handleValueChange(event) {
    const { authentication } = this.props;

    authentication.setValue(event.target.name, event.target.value);
  }

  @action setResetValue() {
    this.resetOpen = !this.resetOpen;
  }

  async sendResetEmail() {
    const { authentication } = this.props;

    if (!authentication.resetEmail) {
      this.setErrorMessage('email');
      return;
    } else if (
      !authentication.resetEmail.includes('@') ||
      !authentication.resetEmail.includes('.')
    ) {
      this.setErrorMessage('authemail');
      return;
    } else {
      this.setErrorMessage('');
    }

    try {
      const data = await authentication.sendResetEmail();
      if (data.message === 'Reset link sent to your email.') {
        this.setFeedbackMessage('reset-success');
        setTimeout(() => {
          this.setFeedbackMessage('');
        }, 5000);
      }
      this.setErrorMessage('');
      authentication.resetEmail = '';
    } catch (error) {
      if (
        error.message === 'Request failed with status code 500' ||
        error.message === '"The given data was invalid."'
      ) {
        this.setErrorMessage('reset-fail');
      }
    }
  }

  @action setErrorMessage(message) {
    this.errorMessage = message;
  }

  @action setFeedbackMessage(message) {
    this.feedback = message;
  }

  showErrorMessage() {
    return (
      <Alert show={!!this.errorMessage} type={Type.ERROR}>
        <Translate component="login-error" keyword={this.errorMessage} />
      </Alert>
    );
  }

  render() {
    const { authentication, toggle } = this.props;

    return (
      <div className="component-reset-password">
        <h3 className="component-reset-password__title">
          <Translate component="login" keyword="forgot-message" />
        </h3>
        <p className="component-reset-password__subtitle">
          <Translate component="login-reset" keyword="info" />
        </p>
        <div className="component-reset-password__input">
          <label htmlFor="reset-label">
            <Translate component="login" keyword="email" />
          </label>
          <input
            type="text"
            name="resetEmail"
            id="resetEmail"
            onChange={this.handleValueChange}
            value={authentication.resetEmail}
          />
        </div>
        <div className="component-reset-password__button">
          <p onClick={this.sendResetEmail}>
            <Translate component="login-reset" keyword="send" />
          </p>
          <span onClick={toggle}>
            <Translate component="product" keyword="back" />
          </span>
        </div>
        {this.feedback ? (
          <Alert show={!!this.feedback} type={Type.SUCCESS}>
            <Translate component="login-error" keyword={this.feedback} />
          </Alert>
        ) : null}

        {this.showErrorMessage()}
      </div>
    );
  }
}
