import React from 'react';
import { PathItem } from './MainMenuItemClickable';
import Translate from '../Translate/Translate';
import { ArrowLongRightIcon, ChevronLeftIcon } from '@heroicons/react/20/solid';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { gql, useQuery } from '@apollo/client';
import LocalizedLink from '../LocalizedLink';

const GET_SUBCATEGORIES = gql`
  query Categories($categoriesId: ID) {
    categories(id: $categoriesId) {
      id
      title
      slug
      hasSubCategories
      generalTitle
    }
  }
`;

type CategoriesItem = {
  id: string;
  title: string;
  slug: string;
  hasSubCategories: boolean;
  generalTitle: string;
};

type Props = {
  onSetPath: (pathItem: PathItem[]) => void;
  path: [PathItem];
  generalTitle: string;
  slug: string;
  onClick: () => void;
};

const SubmenuClickable = ({ onSetPath, path, onClick }: Props) => {
  const activeItem = path[path.length - 1];
  const { loading, error, data } = useQuery<{
    categories: CategoriesItem[];
  }>(GET_SUBCATEGORIES, {
    variables: { categoriesId: activeItem.id },
  });

  if (loading) {
    return;
  }

  if (error || !data) {
    return null;
  }

  const categories = data.categories || [];

  const currentPathSlugs = path.map((pathItem) => {
    return pathItem.slug;
  });

  return (
    <div className="my-2.5 mx-0">
      <div className="py-2 mr-1.5 mb-2.5 flex bg-white items-center w-full overflow-x-scroll overflow-y-hidden">
        {path.map((pathItem) => (
          <div key={pathItem.id} className="group flex">
            <div className="font-axi-bold text-rgrey text-2xl relative group-first-of-type:text-black">
              {pathItem.title}
            </div>
            <div className="group-last-of-type:hidden flex items-center">
              <ArrowLongRightIcon className="h-5 w-5 mx-1.5 fill-rgrey" />
            </div>
          </div>
        ))}
      </div>
      <button
        className="bg-black text-white cursor-pointer w-full border border-solid border-black rounded mt-2.5 flex flex-nowrap items-center"
        onClick={() => {
          // Remove last item from path to navigate back
          onSetPath(path.slice(0, -1));
        }}
      >
        <ChevronLeftIcon className="w-6 h-6 ml-2" />
        <span className="uppercase leading-7 px-2 py-3.5 font-axi-bold text-start">
          <Translate component="product" keyword="back" />
        </span>
      </button>

      {activeItem ? (
        <div>
          <LocalizedLink
            className=" bg-rgreen text-white cursor-pointer w-full border border-solid border-rgreen rounded mt-2.5 flex flex-nowrap items-center"
            to={`/tooted/${currentPathSlugs.join('/')}`}
            onClick={onClick}
          >
            <span className="uppercase leading-7 px-4 py-3.5 font-axi-bold text-start">
              {activeItem.generalTitle || activeItem.title}
            </span>
          </LocalizedLink>
        </div>
      ) : null}

      <div className="flex flex-col">
        {categories.map((category) => (
          <div
            className="flex cursor-pointer border rounded mt-2.5 border-solid border-rgrey-light"
            key={category.id}
          >
            {category.hasSubCategories ? (
              <button
                className="flex flex-nowrap uppercase leading-7 flex-1 px-4 py-3.5 font-axi-bold text-start justify-between items-center"
                onClick={() => {
                  onSetPath([
                    ...path,
                    {
                      title: category.title,
                      id: category.id,
                      slug: category.slug,
                      generalTitle: category.generalTitle,
                    },
                  ]);
                }}
              >
                {category.title}
                <ChevronRightIcon className="w-6 h-6" />
              </button>
            ) : (
              <LocalizedLink
                className="flex flex-nowrap uppercase leading-7 flex-1 px-4 py-3.5 font-axi-bold text-start justify-between items-center"
                to={`/tooted/${currentPathSlugs.join('/')}/${category.slug}`}
                onClick={onClick}
              >
                {category.title}
              </LocalizedLink>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubmenuClickable;
